<template>
  <div class="level is-mobile user" @click="toggleSelect">
    <div class="level-left">
      <div class="level-item">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <ProfilePicture :userId="Number(user.id)" size="small" round />
            </div>

            <div class="level-item">
              <div>
                <p class="user-name">{{ user.getFullName() }}</p>
                <small v-if="showEmail">{{ user.email }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="level-right">
      <div class="level-item">
        <span v-if="user.selected" class="icon is-small">
          <Icon name="icon-check" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    },

    showEmail: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggleSelect () {
      this.user.selected = !this.user.selected
      this.$emit('selected', this.user)
    }
  }
}
</script>

<style scoped>
.user {
  padding: 10px;
  cursor: pointer;
}

.user:hover {
  background-color: rgb(245, 245, 245);
}

.user .user-name {
  font-weight: 600;
  margin-bottom: 0;
}

.level {
  margin-bottom: 0;
}
</style>

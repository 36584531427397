<template>
  <div class="push-bottom-xl">
    <Label :label="$t('hunt.huntLeader.title')" :mandatory="canEdit" />

    <ContentPlaceholders v-if="isLoading" :rounded="true" class="push-bottom-xs">
      <ContentPlaceholdersHeading :img="true" />
    </ContentPlaceholders>

    <div v-else>
      <div v-if="canEdit">
        <p>{{ $t('hunt.huntLeader.helpText') }}</p>

        <MemberDropdown
          :selected-member="huntLeader"
          @member-selected="setHuntLeader">
        </MemberDropdown>
      </div>

      <Member
        v-else
        :member="huntLeader"
        :removable="false"
        :show-status="false">
      </Member>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Member from './Member.vue'
import MemberDropdown from './MemberDropdown.vue'

export default {
  components: {
    Member,
    MemberDropdown
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      canEdit: 'hunt/canEdit',
      members: 'hunt/participants/participants'
    }),

    isLoading () {
      return this.members === null
    },

    huntLeader () {
      const i = this.members.findIndex(member => {
        return member.userId !== null && member.userId === this.hunt.huntLeaderUserId
      })

      return i !== -1 ? this.members[i] : null
    }
  },

  methods: {
    async setHuntLeader (member) {
      try {
        await this.$store.dispatch('hunt/roles/setHuntLeader', member)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('hunt.huntLeader.errorMessage'))
      }
    }
  }

}
</script>

<template>
  <div class="members-tab">
    <AllMembers />
    <hr />
    <HuntLeader />
    <hr />
    <TrackingHunter />
  </div>
</template>

<script>
import HuntLeader from './components//HuntLeader.vue'
import TrackingHunter from './components/TrackingHunter.vue'
import AllMembers from './components/AllMembers.vue'

export default {
  components: {
    AllMembers,
    HuntLeader,
    TrackingHunter
  }
}
</script>

<style lang="scss" scoped>
.members-tab {
  hr {
    height: 1px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}
</style>

<template>
  <div class="modal is-active">
    <div class="modal-background"></div>

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('hunt.addParticipants.title') }}</p>
        <button
          class="delete"
          aria-label="close"
          :disabled="inviting"
          @click="close">
        </button>
      </header>

      <section class="modal-card-body">
        <p>
          {{ $t('hunt.addParticipants.helpText') }}
        </p>

        <UserSearch
          :class="{ 'is-disabled': inviting }"
          @start-search="startSearch"
          @reset-search="resetSearch"
          @user-selected="handleUserSelected" />

        <HuntAreaMembers
          v-if="!showSearch"
          :class="{ 'is-disabled': inviting }"
          @user-selected="handleUserSelected" />

        <div class="tags push-top">
          <SelectedMemberTag
            v-for="member in invitedMembers"
            :key="member.email"
            :member="member"
            :class="{ 'is-disabled': inviting }"
            @user-selected="handleUserSelected" />
        </div>
      </section>

      <footer class="modal-card-foot">
        <div class="flex justify-end" style="width: 100%;">
          <div class="buttons">
            <button
              class="button"
              :disabled="inviting"
              @click="close">
              {{ $t('general.cancel') }}
            </button>

            <button
              class="button is-primary"
              :class="{ 'is-loading': inviting }"
              @click="inviteMembers">
              <span v-html="$tc('hunt.addParticipants.addWithCount', invitedMembers.length, { userCount: invitedMembers.length })"></span>
            </button>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserSearch from './components/UserSearch'
import HuntAreaMembers from './components/HuntAreaMembers.vue'
import SelectedMemberTag from './components/SelectedMemberTag.vue'

export default {
  components: {
    UserSearch,
    HuntAreaMembers,
    SelectedMemberTag
  },

  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      invitedMembers: [],
      showSearch: false,
      inviting: false,
      searching: false,
      nameOrEmail: ''
    }
  },

  computed: {
    ...mapGetters({
      huntAreaMembers: 'huntarea/members/members',
      huntMembers: 'hunt/participants/participants'
    }),

    members () {
      let members = this.huntAreaMembers

      if (members !== null) {
        members = members.filter(this.removeHuntMembersFilter)
        members = members.splice(0).sort(this.sortMemberByFullName)
      }

      return members
    },

    isEmailSearch () {
      return this.nameOrEmail.indexOf('@') !== -1 && this.validateEmail()
    }
  },

  created () {
    this.resetInvitedUsers()
  },

  methods: {
    close () {
      this.$emit('close')
    },

    resetInvitedUsers () {
      if (this.huntAreaMembers !== null) {
        this.huntAreaMembers.forEach(member => {
          member.selected = false
        })
      }

      this.invitedMembers = []
    },

    removeHuntMembersFilter (huntAreaMember) {
      const i = this.huntMembers.findIndex(member => {
        return member.userId === huntAreaMember.id
      })

      return i === -1
    },

    sortMemberByFullName (firstMember, secondMember) {
      return firstMember.getFullName().localeCompare(secondMember.getFullName())
    },

    handleUserSelected (user) {
      if (user.selected) {
        this.invitedMembers.push(user)
      } else {
        const i = this.invitedMembers.indexOf(user)
        this.invitedMembers.splice(i, 1)
      }
    },

    inviteMembers () {
      if (this.invitedMembers.length > 0) {
        this.inviting = true
        const promises = []

        this.invitedMembers.forEach(user => {
          promises.push(this.$store.dispatch('hunt/participants/add', user))
        })

        Promise.all(promises).then(() => {
          this.close()
        })
      } else {
        this.close()
      }
    },

    deselectUser (user) {
      user.selected = false
      this.handleUserSelected(user)
    },

    async search () {
      this.searchResult = null

      if (this.nameOrEmail.length === 0) return

      this.searching = true

      let users = []

      try {
        if (this.isEmailSearch) {
          users = await this.$store.dispatch('user/getByEmail', this.nameOrEmail)
        } else {
          users = await this.$store.dispatch('user/getByName', this.nameOrEmail)
        }
      } catch (error) {
        // Show error panel
      }

      this.searchResult = users
      this.searching = false
    },

    validateEmail () {
      // eslint-disable-next-line
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.nameOrEmail)
    },

    startSearch () {
      this.showSearch = true
      // this.resetInvitedUsers()
    },

    resetSearch () {
      this.showSearch = false
      // this.resetInvitedUsers()
    }
  }
}
</script>

<style scoped>
.modal-card-head {
  background-color: white !important;
  border-bottom: none;
}

.modal-card-foot {
  background-color: white !important;
  border-top: none;
}

.reset-search {
  pointer-events: all !important;
  cursor: pointer !important;
}

.reset-search .icon {
  color: black !important;
}
</style>

<template>
  <div>
    <InlineLoader v-if="members === null" />

    <div v-else>
      <User
        v-for="member in members"
        :key="member.id"
        :user="member"
        :show-email="true"
        @selected="handleUserSelected" />

      <span v-if="members.length === 0">
        {{ $t('hunt.addParticipants.allMembersIncludedInHunt') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import User from './User.vue'

export default {
  components: {
    User
  },

  computed: {
    ...mapGetters({
      huntAreaMembers: 'huntarea/members/members',
      huntMembers: 'hunt/participants/participants'
    }),

    members () {
      let members = this.huntAreaMembers

      if (members !== null) {
        members = members.filter(this.removeHuntMembersFilter)
        members = members.splice(0).sort(this.sortMemberByFullName)
      }

      return members
    }
  },

  methods: {
    removeHuntMembersFilter (huntAreaMember) {
      const i = this.huntMembers.findIndex(member => {
        return member.userId === huntAreaMember.id
      })

      return i === -1
    },

    sortMemberByFullName (firstMember, secondMember) {
      return firstMember.getFullName().localeCompare(secondMember.getFullName())
    },

    handleUserSelected (user) {
      this.$emit('user-selected', user)
    }
  }
}
</script>

<template>
  <span class="tag is-light">
    {{ member.getFullName() }}
    <span @click="deselectUser(member)">
      <Icon name="icon-x" />
    </span>
  </span>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    deselectUser () {
      this.member.selected = false
      this.$emit('user-selected', this.member)
    }
  }
}
</script>

<style scoped>
.tag .icon {
  cursor: pointer;
}
</style>

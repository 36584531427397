<template>
  <Dropdown :text="selectedMember !== null ? selectedMember.getFullName() : placeholder">
    <div v-if="deselectable">
      <DropdownItem @click="select(null)">{{ placeholder }}</DropdownItem>
      <DropdownDivider />
    </div>

    <DropdownItem
      v-for="member in members"
      :key="member.id"
      @click="select(member)">
      {{ member.getFullName() }}
    </DropdownItem>
  </Dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    selectedMember: {
      type: Object,
      default: () => {}
    },

    deselectable: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      active: false
    }
  },

  computed: {
    ...mapGetters({
    }),

    members () {
      let members = this.$store.getters['hunt/participants/participants']
      members = members.filter(member => member.userId !== null)
      return members !== null ? members.slice(0).sort(this.sortMemberByFullName) : null
    }
  },

  methods: {
    sortMemberByFullName (firstMember, secondMember) {
      return firstMember.getFullName().localeCompare(secondMember.getFullName())
    },

    select (member) {
      this.active = false
      this.$emit('member-selected', member)
    },

    toggleActive () {
      this.active = !this.active
    }
  }
}
</script>

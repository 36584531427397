<template>
  <div class="push-bottom-xl">
    <Label :label="$t('hunt.trackingHunter.title')" />

    <ContentPlaceholders v-if="isLoading" :rounded="true" class="push-bottom-xs">
      <ContentPlaceholdersHeading :img="true" />
    </ContentPlaceholders>

    <div v-else>
      <div v-if="canEdit">
        <p class="subtitle is-6">{{ $t('hunt.trackingHunter.helpText') }}</p>

        <MemberDropdown
          :placeholder="$t('hunt.trackingHunter.noneSelected')"
          :deselectable="true"
          :selected-member="trackingHunter"
          @member-selected="setTrackingHunter">
        </MemberDropdown>
      </div>

      <div v-else>
        <Member
          v-if="trackingHunter !== null"
          :member="trackingHunter"
          :removable="false"
          :show-status="false">
        </Member>

        <p v-else class="subtitle is-6">{{ $t('hunt.trackingHunter.noneSelected') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Member from './Member.vue'
import MemberDropdown from './MemberDropdown.vue'

export default {
  components: {
    Member,
    MemberDropdown
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      canEdit: 'hunt/canEdit',
      members: 'hunt/participants/participants'
    }),

    trackingHunter () {
      const i = this.members.findIndex(member => {
        return member.userId !== null && member.userId === this.hunt.afterSearchUserId
      })

      return i !== -1 ? this.members[i] : null
    },

    isLoading () {
      return this.members === null
    }
  },

  methods: {
    setTrackingHunter (member) {
      try {
        this.$store.dispatch('hunt/roles/setTrackingHunter', member)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('hunt.trackingHunter.errorMessage'))
      }
    }
  }
}
</script>

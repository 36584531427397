<template>
  <div class="push-bottom-xl">
    <Label :label="$t('hunt.participants')" />

    <div v-if="isLoading">
      <ContentPlaceholders
        v-for="i in 5"
        :key="i"
        :rounded="true"
        class="push-bottom-xs">
        <ContentPlaceholdersHeading :img="true" />
      </ContentPlaceholders>
    </div>

    <div v-else>
      <Member
        v-for="member in members"
        :key="member.id"
        :member="member"
        :removable="canRemoveMember(member)"
        @removed="handleMemberRemoved(member)">
      </Member>

      <Button
        v-if="canEdit"
        size="small"
        :title="$t('general.add')"
        @click="invite">
      </Button>
    </div>

    <HuntInviteModal
      v-if="showInviteModal"
      @close="hideInviteModal">
    </HuntInviteModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Member from './Member.vue'
import HuntInviteModal from './huntInviteModal/HuntInviteModal.vue'

export default {
  components: {
    HuntInviteModal,
    Member
  },

  data () {
    return {
      showInviteModal: false
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      canEdit: 'hunt/canEdit'
    }),

    isPlanning () {
      return this.hunt.status === 'planning'
    },

    isFinished () {
      return this.hunt.status === 'finished'
    },

    isLoading () {
      return this.members === null
    },

    members () {
      const members = this.$store.getters['hunt/participants/participants']
      return members !== null ? members.slice(0).sort(this.sortMemberByFullName) : null
    }
  },

  methods: {
    isMe (member) {
      const userId = this.$store.getters['auth/getUserId']
      return member.userId === userId
    },

    canRemoveMember (member) {
      return !this.isMe(member) && this.canEdit
    },

    sortMemberByFullName (firstMember, secondMember) {
      return firstMember.getFullName().localeCompare(secondMember.getFullName())
    },

    invite () {
      this.showInviteModal = true
    },

    hideInviteModal () {
      this.showInviteModal = false
    },

    handleMemberRemoved (member) {
      if (this.hunt.huntLeaderUserId !== null && member.userId === this.hunt.huntLeaderUserId) {
        this.setDefaultHuntLeader()
      }

      if (this.hunt.afterSearchUserId !== null && member.userId === this.hunt.afterSearchUserId) {
        this.removeTrackingHunter()
      }
    },

    setDefaultHuntLeader () {
      const userId = this.$store.getters['auth/getUserId']
      const member = this.findMember(userId)
      this.$store.dispatch('hunt/roles/setHuntLeader', member)
    },

    removeTrackingHunter () {
      this.$store.dispatch('hunt/roles/setTrackingHunter', null)
    },

    findMember (userId) {
      const i = this.members.findIndex(member => Number(member.userId) === Number(userId))
      return i !== -1 ? this.members[i] : null
    }
  }
}
</script>

<template>
  <div>
    <form class="push-bottom" @submit.prevent="search">
      <div class="field">
        <div class="control is-expanded has-icons-left" :class="{ 'has-icons-right': nameOrEmail.length > 0 }">
          <input
            v-model="nameOrEmail"
            :placeholder="$t('hunt.addParticipants.searchPlaceholder')"
            type="text"
            class="input"
            @input="handleNameOrEmailChanged" />

          <Icon name="icon-search" class="is-left" />

          <div
            v-if="nameOrEmail.length > 0"
            class="icon icon-x is-right reset-search"
            @click="resetSearch">
          </div>
        </div>
      </div>
    </form>

    <InlineLoader v-if="searching" />

    <div v-if="users !== null">
      <article v-if="users.length === 1 && users[0].id === null" class="message is-warning">
        <div class="message-body">
          {{ $t('hunt.addParticipants.noUserWithEmail') }}
        </div>
      </article>

      <div v-else>
        <small>{{ $t('hunt.addParticipants.searchMatchCounterMessage', { userCount: users.length }) }}</small>
      </div>

      <div v-if="users.length > 0">
        <User
          v-for="user in users"
          :key="user.id"
          :user="user"
          @selected="handleUserSelected">
        </User>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import User from './User.vue'
import UserModel from '@/modules/user/models/userModel'

export default {
  components: {
    User
  },

  data () {
    return {
      nameOrEmail: '',
      users: null,
      searching: false,
      searchTimeout: null
    }
  },

  computed: {
    ...mapGetters({
      huntMembers: 'hunt/participants/participants'
    }),

    isEmailSearch () {
      return this.nameOrEmail.indexOf('@') !== -1 && this.validateEmail()
    }
  },

  methods: {
    async search () {
      this.cancelSearchTimeout()

      this.users = null

      if (this.nameOrEmail.length === 0) {
        this.resetSearch()
      } else {
        this.$emit('start-search')
        this.searching = true

        let users = []

        try {
          if (this.isEmailSearch) {
            users = await this.$store.dispatch('user/getByEmail', this.nameOrEmail)
            if (users.length === 0) {
              users.push(new UserModel({
                email: this.nameOrEmail
              }))
            }
          } else {
            users = await this.$store.dispatch('user/getByName', this.nameOrEmail)
          }

          this.users = this.removeMembersFilter(users)
        } catch (error) {
          // Show error panel
        }

        this.searching = false
        this.$emit('search-completed')
      }
    },

    validateEmail () {
      // eslint-disable-next-line
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.nameOrEmail)
    },

    resetSearch () {
      this.nameOrEmail = ''
      this.users = null
      this.$emit('reset-search')
    },

    handleNameOrEmailChanged () {
      if (this.nameOrEmail.length === 0) {
        this.resetSearch()
      } else {
        this.startSearchTimeout()
      }
    },

    handleUserSelected (user) {
      this.$emit('user-selected', user)
    },

    removeMembersFilter (users) {
      return users.filter(user => {
        return this.huntMembers.findIndex(member => member.email === user.email) === -1
      })
    },

    startSearchTimeout () {
      this.cancelSearchTimeout()

      this.searchTimeout = setTimeout(() => {
        this.search()
      }, 1000)
    },

    cancelSearchTimeout () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
        this.searchTimeout = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input::-ms-clear {
  display: none;
}

.reset-search {
  pointer-events: all !important;
  cursor: pointer !important;

  .icon {
    color: black !important;
  }
}
</style>
